<template>
  <div class="page-accounting">
    <settings-navigation />
    <team-changes-component team="ae" startYear="2023" startMonth="5" />
  </div>
</template>

<script>
// Components
import SettingsNavigation from '../components/SettingsNavigation.vue'
import TeamChangesComponent from '@/agGridV2/components/team-changes.component.vue'

export default {
  components: {
    TeamChangesComponent,
    SettingsNavigation
  },
  data() {
    return {}
  }
}
</script>
